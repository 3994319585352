<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Payment Profiles</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Payment-Profiles' }"
              >Payment Profiles</router-link
            >
          </li>
          <li class="breadcrumb-item active">Add new</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="list-view" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Add New Payment Profile</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form
                id="payment_profile_create"
                @submit.prevent="submitFormData()"
              >
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label
                      >Service Name <span style="color: red">*</span></label
                    >
                    <select class="form-control" name="service_id" required>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="(service, i) in lists.services"
                        :key="i"
                        :value="i"
                      >
                        {{ service }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Study Year <span style="color: red">*</span></label>
                    <select class="form-control" name="year_id" required>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option
                        v-for="year in lists.years"
                        :key="year.id"
                        :value="year.id"
                      >
                        {{ year.value }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label
                      >Number Of installments
                      <span style="color: red">*</span></label
                    >
                    <input
                      required
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="installments"
                      name="num_of_installments"
                      placeholder="Number Of installments"
                    />
                  </div>
                </div>

                <!-- Years: Start -->
                <div v-for="(level, level_id) in lists.levels" :key="level_id">
                  <div class="form-group row">
                    <label
                      class="col-md-12 col-form-label"
                      style="color: rgb(120, 27, 32)"
                    >
                      <i class="custom-left-border"></i> {{ level }}
                    </label>
                  </div>

                  <div
                    class="form-group row"
                    v-for="x in parseInt(installments)"
                    :key="x"
                  >
                    <label class="col-md-2 col-form-label"
                      >Installment {{ x }}
                      <span style="color: red">*</span></label
                    >
                    <div class="col-md-2">
                      <input
                        type="number"
                        min="1"
                        required
                        :name="'installment_value_' + level_id + '_' + x"
                        id=""
                        class="form-control"
                        placeholder="Amount"
                      />
                    </div>
                    <div class="col-md-4">
                      <input
                        type="date"
                        required
                        :name="'start_date_' + level_id + '_' + x"
                        id=""
                        class="form-control"
                        placeholder="from"
                      />
                    </div>
                    <div class="col-md-4">
                      <input
                        type="date"
                        required
                        :name="'deadline_date_' + level_id + '_' + x"
                        id=""
                        class="form-control"
                        placeholder="to"
                      />
                    </div>
                  </div>
                </div>
                <!-- Years: End -->

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import usePaymentProfiles from "@/composables/payment_profiles";
import { ref, inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    FeedbackMessages,
  },
  setup() {
    const installments = ref(1);
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const {
      getListsForPaymentProfile,
      storePaymentProfile,
      lists,
      msgs,
      errors,
    } = usePaymentProfiles();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("add_payment_profiles"))
        router.push({
          name: "401",
        });

      getListsForPaymentProfile();
    });

    const submitFormData = async () => {
      var formDataObj = new FormData();
      const form = document.querySelector("#payment_profile_create");
      Array.from(form.elements).forEach((input) => {
        formDataObj.append(input.name, input.value);
      });

      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await storePaymentProfile(formDataObj).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Payment-Profiles-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      installments,
      disableBtn,
      lists,
      msgs,
      errors,
    };
  },
};
</script>